export default {
  methods: {
    getPrice1(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[0];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[0];
      } else {
        return p_PriceText;
      }
    },
    getPriceSign(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "ก";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "x";
      } else {
        return "";
      }
    },
    getPrice2(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[1];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[1];
      } else {
        return "";
      }
    },
    getBetTypeColor(p_BetType) {
      switch (p_BetType) {
        case "1": //บน
        case "4": //พวง
        case "6": //บน/น";
        case "7": //บน/ก";
        case "8": //บน/ท";
        case "11": //บน/น";
        case "12": //บน/ถ";
        case "13": //ล.บ.";
        case "15": //19/บ";
        case "17": //ค.ต.";
          return "blue--text text--darken-3";

        case "2": // ล่าง
        case "5": //3 ล่าง
        case "9": //ล่าง/น";
        case "10": //ล่าง/ท";
        case "14": //ล.ล";
        case "16": //19/ล";
          return "red--text text--darken-1";

        case "3": //บ+ล
          return "grey--text text--darken-3";

        case "18": //3น.";
        case "19": //3น.";
        case "20": //3น/น";
        case "21": //3น/ก";
        case "22": //3น/ท";
        case "23": //3น/น";
        case "24": //3น/ถ";
        case "25": //3น.";
        case "26": //ต/3น.";
          return "yellow--text text--darken-3";
      }
    },
    getStatusText(p_Status) {
      const statusText = [
        "ปกติ",
        "ลบ",
        "แก้ไข",
        "อั้น",
        "ตีกลับ",
        "ถูก",
        "เลขเต็ม",
      ];
      return statusText[parseInt(p_Status)];
    },
    getStatusColor(p_Status) {
      const statusColor = [
        "grey--text text--darken-3",
        "red--text",
        "deep-orange--text text--accent-4",
        "deep-orange--text text--accent-4",
        "red--text",
        "amber--text text--darken-3",
        "red--text",
      ];
      return statusColor[parseInt(p_Status)];
    },
    getPriceSignColor(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "red--text";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "blue--text";
      } else {
        return "";
      }
    },
  },
};
