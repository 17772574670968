<template>
  <v-dialog v-model="dialog" width="95%" scrollable>
    <v-card>
      <v-card-title class="my-0 pb-0 pt-0 pr-1 pl-4 mt-1 mb-1"
        ><v-icon class="mr-2 pink--text text--darken-1"
          >mdi-alert-circle-check</v-icon
        >
        ยืนยันส่งเลขอั้น <v-spacer></v-spacer>
        <v-icon
          class="pink--text text--darken-1 pa-0 ma-0"
          @click="dialog = false"
          >mdi-close-thick</v-icon
        ></v-card-title
      >
      <v-divider class="pink lighten-3"></v-divider>
      <v-card-text class="px-2" style="">
        <v-data-table
          :items="lines_data"
          item-key="id"
          single-select
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          dense
        >
          <template v-slot:item="{ item }">
            <!-- @contextmenu="popup_menu($event, item)" -->
            <tr>
              <td
                align="left"
                class="pl-0 pr-2 py-1 grey--text text--darken-3"
                style="font-size: 4vw; font-weight: bold"
              >
                {{ item.id }}.
              </td>
              <td
                align="center"
                class="pl-0 pr-2 py-1"
                style="font-size: 4vw; font-weight: bold"
                :class="getBetTypeColor(item.BetTypeID)"
              >
                {{ getBetTypeName(item.BetTypeID) }}
              </td>
              <td
                align="end"
                class="pl-0 pr-2 py-1 grey--text text--darken-3"
                style="font-size: 4vw; font-weight: bold"
              >
                {{ item.Number }}
              </td>
              <td
                class="pl-0 pr-2 py-1 blue--text"
                style="font-size: 4vw; font-weight: bold"
              >
                =
              </td>
              <td
                class="pl-0 pr-2 py-1 grey--text text--darken-3"
                style="font-size: 4vw; font-weight: bold"
              >
                {{ add_comma(getPrice1(item.PriceText)) }}
              </td>
              <td
                class="pl-0 pr-2 py-1"
                style="font-size: 4vw; font-weight: bold"
                :class="getPriceSignColor(item.PriceText)"
              >
                {{ getPriceSign(item.PriceText) }}
              </td>
              <td
                class="pl-0 pr-2 py-1 grey--text text--darken-3"
                style="font-size: 4vw; font-weight: bold"
              >
                {{ add_comma(getPrice2(item.PriceText)) }}
              </td>
              <!-- <td
                align="right"
                class="pa-0"
                style="font-size: 4.0vw; font-weight: bold"
                :class="getStatusColor(item.Status)"
              >
                {{ GetHPText(item.GetPercent) }}
              </td> -->
            </tr>
            <tr>
              <td colspan="7" class="pa-0 ma-0 red--text">
                * {{ GetHpText(item.DETAIL) }}
              </td>
            </tr>
          </template>
        </v-data-table>

        <!-- <v-simple-table dense>

          <template v-slot:default>
            <tbody>
              <tr class="" v-for="item in lines_data" :key="item.LineNumber">
                <td
                  align="left"
                  class="pl-0 pr-2 py-1 grey--text text--darken-3"
                  style="font-size: 4.0vw; font-weight: bold"
                >
                  {{ item.id }}.
                </td>
                <td
                  align="center"
                  class="pl-0 pr-2 py-1"
                  style="font-size: 4.0vw; font-weight: bold"
                  :class="getBetTypeColor(item.BetTypeID)"
                >
                  {{ getBetTypeName(item.BetTypeID) }}
                </td>
                <td
                  align="end"
                  class="pl-0 pr-2 py-1 grey--text text--darken-3"
                  style="font-size: 4.0vw; font-weight: bold"
                >
                  {{ item.Number }}
                </td>
                <td
                  class="pl-0 pr-2 py-1 blue--text"
                  style="font-size: 4.0vw; font-weight: bold"
                >
                  =
                </td>
                <td
                  class="pl-0 pr-2 py-1 grey--text text--darken-3"
                  style="font-size: 4.0vw; font-weight: bold"
                >
                  {{ add_comma(getPrice1(item.PriceText)) }}
                </td>
                <td
                  class="pl-0 pr-2 py-1"
                  style="font-size: 4.0vw; font-weight: bold"
                  :class="getPriceSignColor(item.PriceText)"
                >
                  {{ getPriceSign(item.PriceText) }}
                </td>
                <td
                  class="pl-0 pr-2 py-1 grey--text text--darken-3"
                  style="font-size: 4.0vw; font-weight: bold"
                >
                  {{ add_comma(getPrice2(item.PriceText)) }}
                </td>
                <td
                  align="right"
                  class="pa-0"
                  style="font-size: 4.0vw; font-weight: bold"
                  :class="getStatusColor(item.Status)"
                >
                  {{ GetHPText(item.GetPercent) }}
                </td>
                <td colspan="8">
                    123
                </td>
              </tr>

              
            </tbody>
          </template>
        </v-simple-table> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn class="mr-1 red accent-2" style="width: 50%" dark
          >ลบเลขอั้น</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn class="mr-1 indigo" style="width: 50%" dark> แก้ไขโพย </v-btn>
      </v-card-actions>
      <v-card-actions class="mt-0 pt-0">
        <v-btn class="ml-1 success" style="width: 100%" dark  @click="OnConfirmBet()">
          ยืนยันแทงเลขอั้น</v-btn
        >
        <!-- <v-spacer></v-spacer>

        <v-btn class="ml-1 red" style="width: 50%" dark> ยกเลิกทั้งโพย</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import betLineFunction from "@/mixins/betLineFunction";
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";

export default {
  name: "ConfirmHPDlg",

  mixins: [globalFunctionMixin, betLineFunction, betFunctionMixin],

  watch: {
    dialog(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        // this.$refs.pincode.values = ["", "", "", "", ""];
        console.log("Dialog was opened!");
        this.GetLineData();
        //   this.$refs.pincode.values = ["", "", "", "", ""];
      } else {
        console.log("Dialog was closed!");
      }
    },
  },
  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // lines_data() {
    //     let Data = [];
    //     this.$store.getters["page/LineList"].forEach((element) => {
    //       if (parseInt(element.Status) == 3) Data.push(element);
    //     });
    //     return Data;
    // //   return this.$store.getters["page/LineList"];
    // },
  },
  methods: {
    OnConfirmBet(){
        console.log("CONFIRM");
        this.$emit("OnConfirmBet");
        this.dialog = false;
    },
    GetLineData() {
      let Data = [];
      this.$store.getters["page/LineList"].forEach((element) => {
        if (parseInt(element.Status) == 3) Data.push(element);
      });

      this.lines_data = Data;
      // this.lines_data =  this.$store.getters["page/LineList"];
    },
    GetHpText(p_Text) {
      const HpLine = p_Text.split(",");
      let HpText = "";
      for (let n = 0; n < HpLine.length; n++) {
        // console.log(n);
        let h = HpLine[n].split("|");
        HpText += this.getBetTypeDetailName(parseInt(h[0])) + " " + h[1];
        if (h[2] == "50") {
          HpText += " จ่ายครึ่ง";
        } else {
          HpText += " จ่าย 1/" + Math.abs(parseInt(h[2])) + "";
        }
        if (n < HpLine.length - 1) HpText += " * ";
      }
      return HpText;
    },
    Show() {
      this.dialog = true;
    },
    GetHPText(p_GetPercent) {
      if (p_GetPercent == 50) {
        return "จ่ายครึ่ง";
      } else if (p_GetPercent == 0) {
        return "เลขเต็ม";
      } else if (p_GetPercent < 0) {
        return Math.abs(p_GetPercent) + "/บ.";
      }
      return ";";
    },
    getBetTypeDetailName(betTypeDetail) {
      switch (betTypeDetail) {
        case 1:
          return "3บน";
        case 2:
          return "3โต๊ด";
        case 3:
          return "2บน";
        case 4:
          return "2ล่าง";
        case 5:
          return "ค.ต.";
        case 6:
          return "บ+ล";
        case 7:
          return "พวง";
        case 8:
          return "หมุน";
        case 9:
          return "จ.บ.น.";
        case 10:
          return "จ.บ.ก.";
        case 11:
          return "จ.บ.ท.";
        case 12:
          return "จ.ล.น.";
        case 13:
          return "จ.ล.ท.";
        case 14:
          return "ล.บ.";
        case 15:
          return "ล.ล.";
        case 16:
          return "คู่หน้า";
        case 17:
          return "คู่ถ่าง";
        case 18:
          return "3บน/หน้า";
        case 19:
          return "3โต๊ด/หน้า";
        case 20:
          return "2บน/หน้า";
        case 21:
          return "จมบนหน้า/หน้า";
        case 22:
          return "จมบนกลาง/หน้า";
        case 23:
          return "จมบนห้าย/หน้า";
        case 24:
          return "ลอยบน/หน้า";
        case 25:
          return "คู่หน้า/หน้า";
        case 26:
          return "คู่ถ่าง/หน้า";
        case 27:
          return "พวง/หน้า";
        case 28:
          return "คู่โต๊ด/หน้า";
      }
    },
  },
  data: () => ({
    dialog: false,
    lines_data: [],
  }),
};
</script>

<style></style>
